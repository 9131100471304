<template>
  <main class="user">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <section class="section">
        <div
          class="user-form"
          v-if="formShow"
          :class="{ 'mx-auto': !sidebarShow }"
        >
          <div class="section-title user-title">User {{ userType }}</div>
          <form>
            <div class="form-group row">
              <div class="col col-form-label user-label">
                Role
              </div>
              <div class="col-md">
                <div
                  class="form-check form-check-inline"
                  v-for="item of formRrole"
                  :key="item.value"
                >
                  <input
                    class="form-check-input"
                    type="radio"
                    name="userRole"
                    :id="`userRole_${item.value}`"
                    :value="item.value"
                    v-model="role"
                  />
                  <label
                    class="form-check-label"
                    :for="`userRole_${item.value}`"
                    >{{ item.label }}</label
                  >
                </div>
                <div class="invalid-feedback" v-show="errorMsg.role">
                  {{ errorMsg.role }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col col-form-label user-label">
                Name
              </div>
              <div class="col-md">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <input
                      type="email"
                      class="form-control was-validated"
                      :class="{ 'is-invalid': errorMsg.firstName }"
                      v-model.trim.lazy="firstName"
                      required
                      placeholder="First name"
                    />
                    <div class="invalid-feedback" v-show="errorMsg.firstName">
                      {{ errorMsg.firstName }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <input
                      type="email"
                      class="form-control was-validated"
                      :class="{ 'is-invalid': errorMsg.lastName }"
                      v-model.trim.lazy="lastName"
                      required
                      placeholder="Last name"
                    />
                    <div class="invalid-feedback" v-show="errorMsg.lastName">
                      {{ errorMsg.lastName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col col-form-label user-label">
                Email
              </div>
              <div class="col-md">
                <input
                  type="email"
                  class="form-control was-validated"
                  :class="{ 'is-invalid': errorMsg.email }"
                  v-model.trim.lazy="email"
                  required
                />
                <div class="invalid-feedback" v-show="errorMsg.email">
                  {{ errorMsg.email }}
                </div>
              </div>
            </div>
            <div class="form-group row" v-if="subscriptionStatus">
              <div class="col col-form-label user-label">
                Subscription
              </div>
              <div class="col-md">
                <select
                  class="form-control"
                  :class="{ 'is-invalid': errorMsg.subscriptionId }"
                  v-model.trim.lazy="subscriptionId"
                  required
                >
                  <option
                    v-for="item of subscriptions"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.companyName }}</option
                  >
                </select>
                <div class="invalid-feedback" v-show="errorMsg.subscriptionId">
                  {{ errorMsg.subscriptionId }}
                </div>
              </div>
            </div>
            <div class="form-group form-btn">
              <button
                class="btn btn-secondary btn-cancel"
                @click.prevent="modalShowHide"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-save"
                @click.prevent="SubmitHandler"
              >
                {{ userType === 'New' ? 'Create' : 'Save' }}
              </button>
            </div>
            <div class="alert alert-danger" role="alert" v-show="errorMsg.all">
              {{ errorMsg.all }}
            </div>
          </form>
        </div>
      </section>
    </div>
    <ModalCancelConfirm
      :isOpen.sync="cancelModalShow"
      @yes="goBackHandler"
      @no="modalShowHide"
    />
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ModalCancelConfirm from '../components/ModalCancelConfirm.vue'

export default {
  name: 'UserSetup',
  components: { ModalCancelConfirm },
  data() {
    return {
      userType: '',
      formShow: false,
      roleList: [
        { value: 'CLIENT', label: 'client' },
        { value: 'CUSTOMER_SUCCESS', label: 'customer success' },
        { value: 'SYSTEM_ADMIN', label: 'system admin' }
      ],
      errorMsg: {
        role: '',
        firstName: '',
        lasetName: '',
        email: '',
        subscriptionId: '',
        all: ''
      },
      cancelModalShow: false
    }
  },
  async mounted() {
    const id = this.$route.params.id
    const subscriptionId = this.$route.query.subscriptionId
    id ? (this.userType = 'Edit') : (this.userType = 'New')
    let status = await this['user/pageSetupCreated']({ id, subscriptionId })
    if (status === 'success') {
      this.formShow = true
    } else {
      console.log('userSetup created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      userSelf: state => state.user.self,
      userEdit: state => state.user.edit,
      subscriptions: state => state.subscription.list
    }),
    breadcrumb() {
      return [
        {
          text: 'User',
          to: '/platform/user'
        },
        {
          text: this.userType,
          active: true
        }
      ]
    },
    role: {
      get() {
        return this.userEdit.role
      },
      set(value) {
        this.validationRole(value)
        this.userSetup({ type: 'role', value })
        if (value !== 'CLIENT') {
          this.subscriptionId = null
        }
      }
    },
    firstName: {
      get() {
        return this.userEdit.firstName
      },
      set(value) {
        this.validationName(value, 'first')
        this.userSetup({ type: 'firstName', value })
      }
    },
    lastName: {
      get() {
        return this.userEdit.lastName
      },
      set(value) {
        this.validationName(value, 'last')
        this.userSetup({ type: 'lastName', value })
      }
    },
    email: {
      get() {
        return this.userEdit.email
      },
      set(value) {
        this.validationEmail(value)
        this.userSetup({ type: 'email', value })
      }
    },
    subscriptionId: {
      get() {
        return this.userEdit.subscriptionId
      },
      set(value) {
        this.validationSubscriptionId(value)
        this.userSetup({ type: 'subscriptionId', value })
      }
    },
    subscriptionStatus() {
      return this.role !== 'CUSTOMER_SUCCESS' && this.role !== 'SYSTEM_ADMIN'
    },
    isAdmin() {
      return this.userSelf.role === 'SYSTEM_ADMIN'
    },
    formRrole() {
      return this.isAdmin
        ? this.roleList
        : this.roleList.filter(role => role.value === 'CLIENT')
    }
  },
  methods: {
    ...mapActions(['user/pageSetupCreated', 'user/pageSetupSubmit']),
    ...mapMutations({
      userSetup: 'user/setEditSetup',
      deleteEditSetup: 'user/deleteEditSetup'
    }),
    validationRole(value) {
      const result = value ? true : false
      if (result) {
        this.errorMsg.role = ''
      } else {
        this.errorMsg.role = `Please select one.`
      }
      return result
    },
    validationName(value, type) {
      const result = value ? true : false
      if (result) {
        this.errorMsg[`${type}Name`] = ''
      } else {
        this.errorMsg[`${type}Name`] = `Please enter the ${type} name.`
      }
      return result
    },
    validationEmail(value) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const result = regex.test(String(value).toLowerCase())
      if (result) {
        this.errorMsg.email = ''
      } else {
        this.errorMsg.email = 'Please provide a valid email.'
      }
      return result
    },
    validationSubscriptionId(value) {
      if (this.subscriptionStatus) {
        const result = value ? true : false
        if (result) {
          this.errorMsg.subscriptionId = ''
        } else {
          this.errorMsg.subscriptionId = 'Please Select one.'
        }
        return result
      } else {
        this.errorMsg.subscriptionId = ''
        return true
      }
    },
    validationForm() {
      let validationRole = this.validationRole(this.role)
      let validationFistName = this.validationName(this.firstName, 'first')
      let validationLastName = this.validationName(this.lastName, 'last')
      let validationEmail = this.validationEmail(this.email)
      let validationSubscriptionId = this.validationSubscriptionId(
        this.subscriptionId
      )
      return (
        validationRole &&
        validationFistName &&
        validationLastName &&
        validationEmail &&
        validationSubscriptionId
      )
    },
    async SubmitHandler() {
      this.errorMsg.all = ''
      let validForm = this.validationForm()
      if (validForm) {
        let response = await this['user/pageSetupSubmit']({
          status: this.userType,
          id: this.$route.params.id
        })
        this.saveDone(response)
        return response
      } else {
        this.errorMsg.all = 'Please check form and try it again.'
      }
    },
    saveDone(status) {
      if (status === 'success') {
        this.$router.push('/platform/user')
      } else if (status === 'error') {
        this.errorMsg.all =
          'Please wait a minute and try it again. if not working. please reload this web!'
      } else {
        this.errorMsg.all = status
      }
    },
    modalShowHide() {
      this.cancelModalShow = !this.cancelModalShow
    },
    goBackHandler() {
      this.$router.push('/platform/user')
    }
  }
}
</script>
